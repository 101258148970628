<template>
    <div class="shkw-box">
        <location :locationArr="locationArr"/>
        <div class="titleTap">
            <h1>商会<span>刊物</span></h1>
            <div></div>
        </div>
        <div class="shkwYear">
			<div class="shkw-item" @click="changetab(item)" :class="item == activeYear ? 'active':''" v-for="item,index in yearList" :key="index">{{item}}</div>
		</div>
        <div class="shkw">
            <div class="shkwBox" @click="goShkwDetail(item.shkwId)" v-for="(item,index) in resData.records"
                 :key="index">
                <img :src="item.shkwImage">
                <div class="shkwBoxConten">
                    <div><p>{{item.shkwName}}</p>
                        <section><span>{{item.shkwQS}}</span>期</section>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination style="text-align: right; margin: 60px 0;"
                       background
                       prev-text="上一页"
                       next-text="下一页"
                       layout="prev, pager, next"
                       @current-change="careChange"
                       :page-size=8
                       :total="resData.total">
        </el-pagination>
    </div>
</template>

<script>
    import location from '@/components/location.vue'

    export default {
        name: 'shkwList',
        components: {
            location
        },
        data() {
            return {
                resData: {},
                page: 1,
                locationArr: [{"title": "商会刊物", "url": "/shkw/list"}],
				yearList:[],  // 商会刊物的年份
				activeYear:''
            }
        },
		created() {
			if (/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/mobile/shkw/list"
			        });
			}
		},
        mounted() {
            this.getInfoData()
			this.getshkwYearData()
        },
        methods: {
            careChange(val) {
                this.page = val
                this.getInfoData()
				// console.log(this.page,this.activeYear,'page')
            },
            getInfoData() {
                // window.scrollTo(0,0);
                let _this = this
                this.$http.shkwList({page: _this.page,shkwYear:_this.activeYear}).then(res => {
                    if (res.code == 200) {
                        this.resData = res.data
                    }
                })
            },
            // 跳转至详情页
            goShkwDetail(id) {
                this.$router.push({name: 'shkwDetail', query: {id: id}})
            },
			// 商会刊物年份
			getshkwYearData(){
				this.$http.shkwyearList().then(res =>{
					if(res.code == 200){
						this.yearList = res.data
						this.activeYear = this.yearList[0]
						// console.log(this.activeYear)
						this.getInfoData()
					}
				})
			},
			changetab(year){
				this.activeYear = year
				this.page = 1
				this.getInfoData()
				// console.log(this.page,this.activeYear,'tap')
			}
        }
    }
</script>
<style type="text/css">
    @import '~@/assets/css/shkw.css';

    .shkw-box {
        width: 1200px;
        margin: 0 auto;
    }

    .titleTap {
        text-align: left;
    }

    .titleTap > h1 {
        font-size: 28px;
        margin-top: 56px;
        color: #005bac;
    }

    .titleTap > h1 > span {
        color: #000000;
    }

    .titleTap > div {
        width: 80px;
        height: 4px;
        background-color: #005bac;
        margin: 16px 0 74px 0;
    }
    .shkwYear{
		display: flex;
		margin-bottom: 73px;
	}
	.shkw-item{
    width: 100px;
	height: 40px;
	background-color: #e5e5e5;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #333333;
	font-size: 20px;
	margin-right: 40px;
	cursor: pointer;
	
	}
	.shkw-item.active{
		background-color: #005bac;
		color: #e5e5e5;
	}

</style>
